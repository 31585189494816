.MainLayout {
    text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.MainLayout-Body {
    background-color: #fffdf9;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(5px + 2vmin);
    /* color: white; */
}
.Total-order {
  color: #d38e18;
  font-weight: 800;
  font-size: larger;
  margin: 0.5rem;
  padding: 0.5rem;
}

/* .App-link {
  color: #61dafb;
} */

/* @keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */