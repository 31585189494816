table {
    /* border-collapse: collapse; */
    max-width: 900px;
    width: 100%;
    margin: 2rem auto;
    border-radius: 1rem;
    border: 2px solid #b79e9edb;
    overflow: hidden;
}
th {
        /* border: 2px solid; */
            /* border-radius: 1rem; */
            background-color: #f9a826;
            color: #fce8c5;
}
tr{
        /* border: 2px solid black; */
            background-color: rgba(249, 168, 38, 0.251);
            color: #f9a826;
}
th,
 td {
    /* border: 1px solid #ddd; */
    padding: 8px;
}
th:first-child {
    border-top-left-radius: 1rem;
}
tbody tr:last-child td:first-child  {
    border-bottom-left-radius: 1rem;
}
th:last-child {
    border-top-right-radius: 1rem;
}

tbody tr:last-child td:last-child {
    border-bottom-right-radius: 1rem;
}