.twoCard {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 2rem
}
.twoCard >div:hover {

        transition: all .2s ease-in-out;
        transform: scale(1.025);
        box-shadow: 3px 3px 15px grey;
}

.twoCard div {
    justify-content: center;
    padding: 2rem;
    margin: 1rem;
    background-color: antiquewhite;
    /* margin-left: auto;
  margin-right: auto; */
    box-shadow: 3px 3px 10px  grey;

}
p{
    color: rgba(249, 168, 38);;
}
.twoCard>div>p:first-child{
    color: #d38e18;
    font-weight: 800;
}